export { default as loadNoticias } from "./loadNoticias";
export { default as getEpisodes } from "./getEpisodes";
export { default as getSeries } from "./getSeries";
export { default as loadFilterPlanUser } from "./loadFilterPlanUser";
export { default as loadVideo } from "./loadVideo";
export { default as loadPlanUser } from "./loadPlanUser";
export { default as sendEmail } from "./sendEmail";
export { default as loadVideoFilter } from "./loadVideoFilter";
export { default as loadNews } from "./loadNews";

export { default as getMarketUf } from "./getMarketUf";
export { default as getMarkets } from "./getMarkets";

// CRUD TRAVAS
export { default as getMarketNotifications } from "./getMarketNotifications";
export { default as postMarketNotification } from "./postMarketNotification";
export { default as deleteMarketNotification } from "./deleteMarketNotification";
export { default as updateMarketNotification } from "./updateMarketNotification";
