import {
  CssBaseline,
  Dialog,
  DialogProps,
  DialogTitle,
  ThemeProvider,
  createTheme,
} from "@mui/material";

interface DialogComponentProps extends DialogProps {
  title: string;
}

export default function DialogComponent({
  title,
  children,
  ...props
}: DialogComponentProps) {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#333",
      },
    },
  });
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Dialog {...props}>
        <DialogTitle>{title}</DialogTitle>
        {children}
      </Dialog>
    </ThemeProvider>
  );
}
