import { MarketNotification } from "../../interfaces/travaMercado";
import api from "../../services";
import { getHeaders } from "../auth";

export default async function getMarketNotifications(body: MarketNotification) {
  const response = await api
    .post(`/dashboard/trava`, body, getHeaders())
    .catch((e) => console.log("Erro ao buscar informações de episódios:", e));

  if (response) {
    return response;
  }
}
