import api from "../../services";
import { getHeaders } from "../auth";

export default async function getMarketUf(order: number) {
  const response = await api
    .get(`/cotacoes/mercadosUf/${order}`, getHeaders())
    .catch((e) => console.log("Erro ao buscar informações de episódios:", e));

  if (response) {
    return response.data;
  }
}
