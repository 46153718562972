import React, { Dispatch, SetStateAction, useState } from "react";
import { Market, UserNotification } from "../../../interfaces/travaMercado";
import { Box, Button, Collapse, TableCell, TableRow } from "@mui/material";
import { deleteMarketNotification } from "../../../utils/api";
import { DeleteRounded } from "@mui/icons-material";

interface RowProps {
  row: UserNotification;
  markets: Market[];
  setIsSnackOpen: Dispatch<SetStateAction<boolean>>;
  setIsNotificationsDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsMarketDialogOpen: Dispatch<SetStateAction<boolean>>;
  setInputValues: Dispatch<any>;
}

export default function Row({
  row,
  markets,
  setIsSnackOpen,
  setIsNotificationsDialogOpen,
  setIsMarketDialogOpen,
  setInputValues,
}: RowProps) {
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <>
      <TableRow key={row.tr_id} onClick={() => setOpen(!open)}>
        <TableCell component="th" scope="row">
          {
            markets?.find(
              (element: any) => element.ordem === parseInt(row.tr_mercado)
            )?.nome
          }
        </TableCell>
        <TableCell align="right">{row.tr_uf}</TableCell>
        <TableCell align="right">{row.tr_min}</TableCell>
        <TableCell align="right">{row.tr_max}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} className="flex gap-3 justify-end">
              <Button
                disabled={isDisabled}
                variant="contained"
                color="error"
                onClick={() => {
                  setIsDisabled(true);
                  deleteMarketNotification({
                    tr_id: row.tr_id.toString(),
                  }).finally(() => {
                    setIsDisabled(false);
                    setIsSnackOpen(true);
                  });
                }}
              >
                <DeleteRounded />
              </Button>
              <Button
                disabled={isDisabled}
                variant="contained"
                color="primary"
                onClick={() => {
                  setInputValues(row);
                  setIsMarketDialogOpen(true);
                  setIsNotificationsDialogOpen(false);
                }}
              >
                Editar
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
