import api from "../../services";
import { getHeaders } from "../auth";

export default async function deleteMarketNotification(body: object) {
  const response = await api
    .delete(`/dashboard/trava`, { ...getHeaders(), data: body })
    .catch((e) => console.log("Error while deleting market notification:", e));

  if (response) {
    return response.data;
  }
}
