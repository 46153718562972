import api from "../../services";
import { getHeaders } from "../auth";

export default async function getMarketNotifications() {
  const response = await api
    .get(`/dashboard/trava`, getHeaders())
    .catch((e) => console.log("Erro ao buscar informações de episódios:", e));

  if (response) {
    return response.data;
  }
}
