import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../../components/Nav";
import api from "../../services";
import "./style.scss";
import Loading from "../../assets/images/jsx/Loading";
import Vimeo from "@u-wave/react-vimeo";
import { getHeaders, loadAuth, loadSituation } from "../../utils/auth";
import { getSeries } from "../../utils/api";
import { Box } from "@mui/material";
import getUserToken from "../../utils/auth/getUserToken";

export default function Movie() {
  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [seriesData, setSeriesData] = useState<any>([]);
  const [episodesData, setEpisodesData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);

  //pegando o carregamento de dados
  useEffect(() => {
    loadAuth(handleAuth);
    loadSituation(handleSituation);
    getSeries(setSeriesData, getEpisodes);
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine) {
        navigate(`/home`);
        console.log("Status da internet: " + navigator.onLine);
      } else {
        navigate(`/offline`);
        console.log("Status da internet: " + navigator.onLine);
      }
    };
    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  async function getEpisodes(id: any) {
    setLoading(true);
  
    await api
      .get(`/videos/series/${id}`, getHeaders())
      .then((response) => {
        // console.log(response.data);
        setEpisodesData(response.data);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => console.log("Erro ao buscar informações de episódios:", e));
  }

  const renderSeries = seriesData.map((serie: any, index: any) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      const series = document.querySelectorAll(".button-image");
      const seriesContainer = document.querySelector(".series-container");
      seriesContainer?.scrollTo({
        left: 0, // Adjust the scroll distance as needed
        behavior: "smooth", // Apply smooth scroll behavior
      });

      for (var i = 0; i < series.length; i++) {
        series[i].classList.remove("selected-series");
      }
      const { value } = e.currentTarget;
      getEpisodes(value);
      e.currentTarget.classList.add("selected-series");
    };

    return (
      <button
        className={`button-image ${index === 0 ? "selected-series" : ""}`}
        key={serie.serie_id}
        value={serie.serie_id}
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <img
          className="serie-image"
          src={`https://ruralbusiness.com.br/series/series/${serie.serie_capa}`}
        />
      </button>
    );
  });

  const renderEpisodes = episodesData.map((episode: any) => {
    return (
      <div className="episode">
        {!episode.cap_cdn && (
              <Box
                component={"iframe"}
                src={`https://player.vimeo.com/video/${episode.cap_video}`}
                width="100%"
                height="250px"
                className="videoIframe"
                allow="autoplay; fullscreen; picture-in-picture"
                border={"none"}
              />
            )}
            {episode.cap_cdn && (
              <iframe
                src={`https://player.rbvideo.com.br/index.html?id=${episode.cap_cdn}&thumb=${episode.cap_thumb}&cli_id=${getUserToken()}&app=mobile`} //+ "&preload=false"
                width={"100%"}
                allow="fullscreen; accelerometer; gyroscope; autoplay ;picture-in-picture"
                allowFullScreen
                />
            )}

        <p>
          Capítulo {episode.cap_ordem} - {episode.cap_nome}
        </p>
      </div>
    );
  });

  //manipulando o resultado do get da Auth
  function handleAuth(auth: boolean) {
    if (!auth) return;

    localStorage.removeItem("userJWT");
    navigate("/");
  }

  function handleSituation(data?: any) {
    if (data.situation !== "Pago") {
      navigate("/contract");
    }
  }

  return (
    <>
      {/* <AccountButton /> */}
      <div className="series-container">{renderSeries}</div>
      {loading ? (
        <Loading />
      ) : (
        <div className="episodes-container">{renderEpisodes}</div>
      )}
      <Nav />
    </>
  );
}
