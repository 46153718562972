import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SituationUserDtoHandler } from "../../dtos/SituationUserDto";
import Movie from "../../iconComponents/movie";
import Price from "../../iconComponents/price";
import Graphic from "../../iconComponents/graphic";
import LogoMenu from "../../assets/logoMenu.png";
import LogoMenuOff from "../../assets/logoMenuOff.png";

import "./style.scss";
import api from "../../services";
import News from "../../iconComponents/news";
import { getHeaders } from "../../utils/auth";

export default function Nav() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isMarketClient, setIsMarketClient] = useState(1);
  const isMarketClientRef = useRef(isMarketClient);

  useEffect(() => {
    loadMarketClient();
  }, []);

  async function loadMarketClient() {
    await api
      .get("/contratos/situacao", getHeaders())
      .then((res) => {
        const marketClient = SituationUserDtoHandler.getByDatabase(res.data[0]);
        isMarketClientRef.current = marketClient.marketplace;
        setIsMarketClient(isMarketClientRef.current);
      })
      .catch(() => console.log("erro ao carregar mercado do usuario"));
  }

  return (
    <>
      <div
        className={
          pathname === "/" ||
          pathname === "/login" ||
          pathname === "/offline" ||
          isMarketClientRef.current === -1
            ? `nav_off`
            : "container_nav"
        }
      >
        <div className="box_list">
          <ul className="nav__list">
            <li className="nav__item" onClick={() => navigate("/movie")}>
              <Movie
                className={pathname === "/movie" ? "icon_active" : "icon"}
              />
              <p
                className={
                  pathname === "/movie" ? "nav_text_active" : "nav_text"
                }
              >
                Séries
              </p>
            </li>
            <li className="nav__item" onClick={() => navigate("/price")}>
              <Price
                className={pathname === "/price" ? "icon_active" : "icon"}
              />
              <p
                className={
                  pathname === "/price" ? "nav_text_active" : "nav_text"
                }
              >
                Cotações
              </p>
            </li>
            <li className="nav__item" onClick={() => navigate("/home")}>
              <span className="circle">
                <img
                  src={LogoMenu}
                  className={pathname === "/home" ? "logo_menu" : "hidden"}
                  alt="Logo Rural Business"
                />
                <img
                  src={LogoMenuOff}
                  className={pathname === "/home" ? "hidden" : "logo_menu"}
                  alt="Logo Rural Business"
                />
              </span>
              <p
                className={
                  pathname === "/home" ? "nav_text_active" : "nav_text"
                }
              >
                Vídeos
              </p>
            </li>
            <li className="nav__item" onClick={() => navigate("/graphic")}>
              <Graphic
                className={pathname === "/graphic" ? "icon_active" : "icon"}
              />
              <p
                className={
                  pathname === "/graphic" ? "nav_text_active" : "nav_text"
                }
              >
                Gráficos
              </p>
            </li>
            <li className="nav__item" onClick={() => navigate("/news")}>
              <News className={pathname === "/news" ? "icon_active" : "icon"} />
              <p
                className={
                  pathname === "/news" ? "nav_text_active" : "nav_text"
                }
              >
                Alertas
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
