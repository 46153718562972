import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../../components/Nav";
import "./style.scss";
import { getUserToken, loadAuth, loadSituation } from "../../utils/auth";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TextField,
} from "@mui/material";
import { AddRounded, ListAltRounded } from "@mui/icons-material";
import {
  getMarketUf,
  getMarkets,
  getMarketNotifications,
  postMarketNotification,
  updateMarketNotification,
} from "../../utils/api";
import { Market, UserNotification } from "../../interfaces/travaMercado";
import Row from "../../components/Table/Row/Row";
import SnackbarComponent from "../../components/Snackbar/Snackbar";
import DialogComponent from "../../components/Dialog/Dialog";

interface UF {
  id: number;
  uf: string;
}

export default function Price() {
  const navigate = useNavigate();
  const [marketNotifications, setMarketNotifications] = useState<
    UserNotification[]
  >([]);
  const [markets, setMarkets] = useState<Market[]>([]);
  const [ufList, setUfList] = useState<UF[]>([]);
  const [inputValues, setInputValues] = useState<any>({
    tr_mercado: null,
    tr_uf: null,
    tr_min: null,
    tr_max: null,
  });
  const [isMarketDialogOpen, setIsMarketDialogOpen] = useState<boolean>(false);
  const [isNotificationsDialogOpen, setIsNotificationsDialogOpen] =
    useState<boolean>(false);
  const [isSnackOpen, setIsSnackOpen] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingInsert, setIsLoadingInsert] = useState<boolean>(false);

  useEffect(() => {
    loadAuth(handleAuth);
    loadSituation(handleSituation);
    // Update network status
    const handleStatusChange = () => {
      if (navigator.onLine) {
        navigate(`/home`);
        console.log("Status da internet: " + navigator.onLine);
      } else {
        navigate(`/offline`);
        console.log("Status da internet: " + navigator.onLine);
      }
    };
    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  //manipulando o resultado do get da Auth
  function handleAuth(auth: boolean) {
    if (!auth) return;

    localStorage.removeItem("userJWT");
    navigate("/");
  }

  function handleSituation(data?: any) {
    if (data.situation !== "Pago") {
      navigate("/contract");
    }
  }

  useEffect(() => {
    Promise.all([getMarkets(), getMarketNotifications()])
      .then(([markets, notifications]: [Market[], UserNotification[]]) => {
        setMarkets(markets);
        setMarketNotifications(notifications);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
      });
  }, [isSnackOpen]);

  useEffect(() => {
    if (inputValues.tr_mercado) {
      setIsLoading(true);
      getMarketUf(inputValues.tr_mercado)
        .then((res) => {
          setUfList(res);
          if (res?.error) {
            setHasError(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setHasError(true);
        });
    } else {
      setUfList([]);
      setInputValues((prevState: any) => ({
        ...prevState,
        tr_uf: null,
      }));
    }
  }, [inputValues.tr_mercado]);

  function onClose() {
    setIsNotificationsDialogOpen(false);
    setIsMarketDialogOpen(false);
    setInputValues({
      tr_mercado: null,
      tr_uf: null,
      tr_min: null,
      tr_max: null,
    });
    setUfList([]);
  }

  return (
    <>
      <DialogComponent
        title={`${
          inputValues?.tr_id ? "Atualizar" : "Adicionar Nova"
        } Trava de Mercado`}
        open={isMarketDialogOpen}
        onClose={onClose}
        fullWidth
        scroll="paper"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            console.log(inputValues);
            setIsLoadingInsert(true);
            if (inputValues?.tr_id) {
              updateMarketNotification(inputValues)
                .then((res) => {
                  console.log(res);
                  setIsSnackOpen(true);
                  setIsMarketDialogOpen(false);
                })
                .finally(() => {
                  setIsLoadingInsert(false);
                  setInputValues({
                    tr_mercado: null,
                    tr_uf: null,
                    tr_min: null,
                    tr_max: null,
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              postMarketNotification(inputValues)
                .then((res) => {
                  console.log(res);
                  setIsSnackOpen(true);
                  setIsMarketDialogOpen(false);
                })
                .finally(() => {
                  setIsLoadingInsert(false);
                  setInputValues({
                    tr_mercado: null,
                    tr_uf: null,
                    tr_min: null,
                    tr_max: null,
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }}
        >
          <DialogContent className="flex flex-col gap-3">
            <div className="flex flex-col">
              <FormHelperText>Selecione um Mercado</FormHelperText>
              <Select
                label="Selecione um Mercado"
                MenuProps={{ style: { height: "300px" } }}
                value={inputValues.tr_mercado}
                disabled={inputValues?.tr_id}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const parsedValue =
                    typeof inputValue === "number"
                      ? inputValue.toString()
                      : typeof inputValue === "string"
                      ? inputValue
                      : undefined;
                  if (parsedValue) {
                    setInputValues((prevState: any) => ({
                      ...prevState,
                      tr_mercado: parsedValue,
                    }));
                  }
                }}
                required
              >
                {markets?.map((market) => (
                  <MenuItem value={market.ordem}>{market.nome}</MenuItem>
                ))}
              </Select>
            </div>
            {(ufList.length > 0 || isLoading) && (
              <div className="flex flex-col">
                <FormHelperText>
                  Selecione uma UF {isLoading && "(Carregando...)"}
                </FormHelperText>
                <Select
                  label="Selecione uma UF"
                  MenuProps={{ style: { height: "300px" } }}
                  value={inputValues.tr_uf}
                  disabled={inputValues?.tr_id}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const parsedValue =
                      typeof inputValue === "number"
                        ? inputValue.toString()
                        : typeof inputValue === "string"
                        ? inputValue
                        : undefined;
                    if (parsedValue) {
                      setInputValues((prevState: any) => ({
                        ...prevState,
                        tr_uf: parsedValue,
                      }));
                    }
                  }}
                  required={ufList.length > 0 ? true : isLoading ? true : false}
                >
                  {ufList?.map((uf) => (
                    <MenuItem value={uf.uf}>{uf.uf}</MenuItem>
                  ))}
                </Select>
              </div>
            )}
            <div className="flex gap-3">
              <TextField
                label="Valor Mínimo"
                placeholder="110"
                type="number"
                required
                value={inputValues.tr_min}
                onChange={(e) => {
                  setInputValues((prevState: any) => ({
                    ...prevState,
                    tr_min: parseInt(e.target.value),
                  }));
                }}
              />
              <TextField
                label="Valor Máximo"
                placeholder="130"
                type="number"
                required
                value={inputValues.tr_max}
                onChange={(e) => {
                  setInputValues((prevState: any) => ({
                    ...prevState,
                    tr_max: parseInt(e.target.value),
                  }));
                }}
              />
            </div>
          </DialogContent>
          <DialogActions className="mt-3">
            <Button onClick={onClose}>Cancelar</Button>
            <Button
              type="submit"
              variant="contained"
              autoFocus
              disabled={isLoadingInsert}
            >
              {isLoadingInsert
                ? "Carregando..."
                : inputValues?.tr_id
                ? "Atualizar"
                : "Salvar"}
            </Button>
          </DialogActions>
        </form>
      </DialogComponent>
      <DialogComponent
        title="Minhas Travas Salvas"
        open={isNotificationsDialogOpen}
        onClose={onClose}
        fullWidth
        scroll="paper"
      >
        {marketNotifications.length <= 0 && (
          <DialogContent className="space-y-3">
            <DialogContentText>
              Nenhuma Trava Foi Encontrada...
            </DialogContentText>
            <Button
              size="small"
              color="success"
              variant="contained"
              startIcon={<AddRounded />}
              onClick={() => {
                setIsMarketDialogOpen(true);
                setIsNotificationsDialogOpen(false);
              }}
              style={{
                color: "white",
                borderRadius: 32,
                backgroundColor: "#5f5e5e",
                textTransform: "capitalize",
              }}
            >
              Adicionar Nova Trava de Mercado
            </Button>
          </DialogContent>
        )}
        <TableContainer component={Paper} className="p-3">
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableCell>Cotação</TableCell>
              <TableCell align="right">UF</TableCell>
              <TableCell align="right">Min.</TableCell>
              <TableCell align="right">Máx.</TableCell>
            </TableHead>
            <TableBody>
              {marketNotifications.map((row) => (
                <Row
                  row={row}
                  markets={markets}
                  setIsSnackOpen={setIsSnackOpen}
                  setIsNotificationsDialogOpen={setIsNotificationsDialogOpen}
                  setIsMarketDialogOpen={setIsMarketDialogOpen}
                  setInputValues={setInputValues}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogComponent>

      <div className="container_btn_period mx-3 mt-6">
        <Button
          size="small"
          color="success"
          variant="contained"
          startIcon={<AddRounded />}
          onClick={() => {
            setIsMarketDialogOpen(true);
          }}
          style={{
            borderRadius: 32,
            backgroundColor: "#5f5e5e",
            textTransform: "capitalize",
          }}
        >
          Nova Trava de Mercado
        </Button>
        <Button
          color="primary"
          size="small"
          variant="contained"
          startIcon={<ListAltRounded />}
          onClick={() => {
            setIsNotificationsDialogOpen(true);
          }}
          style={{
            borderRadius: 32,
            backgroundColor: "#5f5e5e",
            textTransform: "capitalize",
          }}
        >
          Minhas Travas
        </Button>
      </div>
      <div
        className="contentPrice"
        style={{
          overflow: "hidden",
          height: `${window.document.body.scrollHeight}px`,
        }}
      >
        <iframe
          src={`https://ruralbusiness.com.br/rbvideo/agroresumo.asp?token=${getUserToken()}`}
          width={"100%"}
          frameBorder="no"
          height={`${window.document.body.scrollHeight + 80}px`}
          id="framePrice"
          className="framePrice"
        ></iframe>
      </div>
      <Nav />
      <SnackbarComponent
        onAlertClose={() => setIsSnackOpen(false)}
        onSnackbarClose={() => setIsSnackOpen(false)}
        open={isSnackOpen}
        severity="success"
        text="Ação Bem-Sucedida!"
        variant="filled"
      />
      <SnackbarComponent
        onAlertClose={() => setHasError(false)}
        onSnackbarClose={() => setHasError(false)}
        open={hasError}
        severity="error"
        text="Um erro inesperado ocorreu, tente novamente."
        variant="filled"
      />
    </>
  );
}
