import {
  Alert,
  AlertProps,
  Snackbar,
  SnackbarCloseReason,
} from "@mui/material";

interface SnackbarProps {
  text: string;
  open: boolean;
  variant: AlertProps["variant"];
  severity: AlertProps["severity"];
  onAlertClose: AlertProps["onClose"];
  onSnackbarClose:
    | ((
        event: Event | React.SyntheticEvent<any, Event>,
        reason: SnackbarCloseReason
      ) => void)
    | undefined;
}

export default function SnackbarComponent({
  text,
  open,
  variant,
  severity,
  onAlertClose,
  onSnackbarClose,
}: SnackbarProps) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={2000}
      onClose={onSnackbarClose}
    >
      <Alert variant={variant} severity={severity} onClose={onAlertClose}>
        {text}
      </Alert>
    </Snackbar>
  );
}
